export class InvoiceCounter {


	public currMonTotal: number = 0;
	public currMonEquipCount: number = 0;
	public currMonSd1NotCreated: number = 0;
	public currMonSd1InProgress: number = 0;
	public currMonSd1Approved: number = 0;
	public currMonSd2NotCreated: number = 0;
	public currMonSd2InProgress: number = 0;
	public currMonSd2Approved: number = 0;
	public currMonSd3NotCreated: number = 0;
	public currMonSd3InProgress: number = 0;
	public currMonSd3Approved: number = 0;
	public currMonSd4NotCreated: number = 0;
	public currMonSd4InProgress: number = 0;
	public currMonSd4Approved: number = 0;
	public currMonInvNotCreated: number = 0;
	public currMonInvInProgress: number = 0;
	public currMonInvApproved: number = 0;
	public currMonSd1NotCrEquipCount: number = 0;
	public currMonSd1ProgEquipCount: number = 0;
	public currMonSd1ApprEquipCount: number = 0;
	public currMonSd2NotCrEquipCount: number = 0;
	public currMonSd2ProgEquipCount: number = 0;
	public currMonSd2ApprEquipCount: number = 0;
	public currMonSd3NotCrEquipCount: number = 0;
	public currMonSd3ProgEquipCount: number = 0;
	public currMonSd3ApprEquipCount: number = 0;
	public currMonSd4NotCrEquipCount: number = 0;
	public currMonSd4ProgEquipCount: number = 0;
	public currMonSd4ApprEquipCount: number = 0;
	public currMonInvNotCrEquipCount: number = 0;
	public currMonInvProgEquipCount: number = 0;
	public currMonInvApprEquipCount: number = 0;

	public currMonTotal1: number = 0;
	public currMonEquipCount1: number = 0;
	public currMonTotal2: number = 0;
	public currMonEquipCount2: number = 0;

	public oldMonTotal1: number = 0;
	public oldMonEquipCount1: number = 0;
	public oldMonTotal2: number = 0;
	public oldMonEquipCount2: number = 0;

	public oldMonTotal: number = 0;
	public oldMonEquipCount: number = 0;
	public oldMonSd1NotCreated: number = 0;
	public oldMonSd1InProgress: number = 0;
	public oldMonSd1Approved: number = 0;
	public oldMonSd2NotCreated: number = 0;
	public oldMonSd2InProgress: number = 0;
	public oldMonSd2Approved: number = 0;
	public oldMonSd3NotCreated: number = 0;
	public oldMonSd3InProgress: number = 0;
	public oldMonSd3Approved: number = 0;
	public oldMonSd4NotCreated: number = 0;
	public oldMonSd4InProgress: number = 0;
	public oldMonSd4Approved: number = 0;
	public oldMonInvNotCreated: number = 0;
	public oldMonInvInProgress: number = 0;
	public oldMonInvApproved: number = 0;
	public oldMonSd1NotCrEquipCount: number = 0;
	public oldMonSd1ProgEquipCount: number = 0;
	public oldMonSd1ApprEquipCount: number = 0;
	public oldMonSd2NotCrEquipCount: number = 0;
	public oldMonSd2ProgEquipCount: number = 0;
	public oldMonSd2ApprEquipCount: number = 0;
	public oldMonSd3NotCrEquipCount: number = 0;
	public oldMonSd3ProgEquipCount: number = 0;
	public oldMonSd3ApprEquipCount: number = 0;
	public oldMonSd4NotCrEquipCount: number = 0;
	public oldMonSd4ProgEquipCount: number = 0;
	public oldMonSd4ApprEquipCount: number = 0;
	public oldMonInvNotCrEquipCount: number = 0;
	public oldMonInvProgEquipCount: number = 0;
	public oldMonInvApprEquipCount: number = 0;


	//FIn 02-New counter model

	public currMonTotalClinic: number = 0;
	public currMonTotalEquip: number = 0;
	public currMonFin08bCreateClinic: number = 0;
	public currMonFin08bCreateEquip: number = 0;
	public currMonFin08bInProgressClinic: number = 0;
	public currMonFin08bInProgressEquip: number = 0;
	public currMonFin08bApprovedClinic: number = 0;
	public currMonFin08bApprovedEquip: number = 0;
	public currMonFin08cCreateClinic: number = 0;
	public currMonFin08cCreateEquip: number = 0;
	public currMonFin08cInProgressClinic: number = 0;
	public currMonFin08cInProgressEquip: number = 0;
	public currMonFin08cApprovedClinic: number = 0;
	public currMonFin08cApprovedEquip: number = 0;
	public currMonFin08CreateClinic: number = 0;
	public currMonFin08CreateEquip: number = 0;
	public currMonFin08InProgressClinic: number = 0;
	public currMonFin08InProgressEquip: number = 0;
	public currMonFin08ApprovedClinic: number = 0;
	public currMonFin08ApprovedEquip: number = 0;
	public currMonFin03CreateClinic: number = 0;
	public currMonFin03CreateEquip: number = 0;
	public currMonFin03InProgressClinic: number = 0;
	public currMonFin03InProgressEquip: number = 0;
	public currMonFin03ApprovedClinic: number = 0;
	public currMonFin03ApprovedEquip: number = 0;
	public currMonFin02CreateClinic: number = 0;
	public currMonFin02CreateEquip: number = 0;
	public currMonFin02InProgressClinic: number = 0;
	public currMonFin02InProgressEquip: number = 0;
	public currMonFin02ApprovedClinic: number = 0;
	public currMonFin02ApprovedEquip: number = 0;

	// Fin 02 - old month 
	public oldMonotalClinic: number = 0;
	public oldMonotalEquip: number = 0;
	public oldMonFin08bCreateClinic: number = 0;
	public oldMonFin08bCreateEquip: number = 0;
	public oldMonFin08bInProgressClinic: number = 0;
	public oldMonFin08bInProgressEquip: number = 0;
	public oldMonFin08bApprovedClinic: number = 0;
	public oldMonFin08bApprovedEquip: number = 0;
	public oldMonFin08cCreateClinic: number = 0;
	public oldMonFin08cCreateEquip: number = 0;
	public oldMonFin08cInProgressClinic: number = 0;
	public oldMonFin08cInProgressEquip: number = 0;
	public oldMonFin08cApprovedClinic: number = 0;
	public oldMonFin08cApprovedEquip: number = 0;
	public oldMonFin08CreateClinic: number = 0;
	public oldMonFin08CreateEquip: number = 0;
	public oldMonFin08InProgressClinic: number = 0;
	public oldMonFin08InProgressEquip: number = 0;
	public oldMonFin08ApprovedClinic: number = 0;
	public oldMonFin08ApprovedEquip: number = 0;
	public oldMonFin03CreateClinic: number = 0;
	public oldMonFin03CreateEquip: number = 0;
	public oldMonFin03InProgressClinic: number = 0;
	public oldMonFin03InProgressEquip: number = 0;
	public oldMonFin03ApprovedClinic: number = 0;
	public oldMonFin03ApprovedEquip: number = 0;
	public oldMonFin02CreateClinic: number = 0;
	public oldMonFin02CreateEquip: number = 0;
	public oldMonFin02InProgressClinic: number = 0;
	public oldMonFin02InProgressEquip: number = 0;
	public oldMonFin02ApprovedClinic: number = 0;
	public oldMonFin02ApprovedEquip: number = 0;

	//Fin 02A - New Model

	//    public  currMonTotalClinic : number = 0;
	// public currMonTotalEquip : number = 0;
	public currMonFin07CreateClinic: number = 0;
	public currMonFin07CreateEquip: number = 0;
	public currMonFin07InProgressClinic: number = 0;
	public currMonFin07InProgressEquip: number = 0;
	public currMonFin07ApprovedClinic: number = 0;
	public currMonFin07ApprovedEquip: number = 0;
	public currMonFin03aCreateClinic: number = 0;
	public currMonFin03aCreateEquip: number = 0;
	public currMonFin03aInProgressClinic: number = 0;
	public currMonFin03aInProgressEquip: number = 0;
	public currMonFin03aApprovedClinic: number = 0;
	public currMonFin03aApprovedEquip: number = 0;
	public currMonFin02aCreateClinic: number = 0;
	public currMonFin02aCreateEquip: number = 0;
	public currMonFin02aInProgressClinic: number = 0;
	public currMonFin02aInProgressEquip: number = 0;
	public currMonFin02aApprovedClinic: number = 0;
	public currMonFin02aApprovedEquip: number = 0;

   
	//Fin 02A - old month 
	public oldMonTotalClinic: number = 0;
	public oldMonTotalEquip: number = 0;
	public oldMonFin07CreateClinic: number = 0;
	public oldMonFin07CreateEquip: number = 0;
	public oldMonFin07InProgressClinic: number = 0;
	public oldMonFin07InProgressEquip: number = 0;
	public oldMonFin07ApprovedClinic: number = 0;
	public oldMonFin07ApprovedEquip: number = 0;
	public oldMonFin03aCreateClinic: number = 0;
	public oldMonFin03aCreateEquip: number = 0;
	public oldMonFin03aInProgressClinic: number = 0;
	public oldMonFin03aInProgressEquip: number = 0;
	public oldMonFin03aApprovedClinic: number = 0;
	public oldMonFin03aApprovedEquip: number = 0;
	public oldMonFin02aCreateClinic: number = 0;
	public oldMonFin02aCreateEquip: number = 0;
	public oldMonFin02aInProgressClinic: number = 0;
	public oldMonFin02aInProgressEquip: number = 0;
	public oldMonFin02aApprovedClinic: number = 0;
	public oldMonFin02aApprovedEquip: number = 0;


}
